
import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({noIndex, ...props})
{
	const { site, ogpImage } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						local
						title
						description
						site_name
						site_url
						#image
						twitter {
							id
							card
						}
					}
				}
				ogpImage : file(relativePath: { eq: "ogp.png" }) {
                	publicURL
            	}
			}
		`
	)
	const info = Object.assign({}, site.siteMetadata, props)
	console.log("<<<<<[[[[[[[[[",info.image)
	const title = !info.subtitle ? info.title : `${info.subtitle} | ${info.title}`
	const image = `${info.site_url}${info.image ? info.image: ogpImage.publicURL}`
	const expected = info.description && info.description.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').substr(0, 140);
	return (
		<Helmet title={title} htmlAttributes={{
			lang: info.local
		}}>
			{
				noIndex && <meta name="robots" content="noindex"/>
			}
			<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"/>
			<meta name="mobile-web-app-capable" content="yes"/>
			<meta name="apple-mobile-web-app-capable" content="yes"/>{/*ホーム画面から起動時にアプリのようにする*/}
			{/* <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/> */}
			{/*ノッチまわりの表示*/}

			<meta http-equiv="X-UA-Compatible" content="IE=edge"/>

			<meta name="description" content={expected} />
			<meta name="image" content={image} />
			<meta property="og:locale" content={info.local} />
			<meta property="og:type" content={ info.isArticle ? 'article' : 'website'} />
			<meta property="og:url" content={info.site_url} />
			<meta property="og:title" content={title}/>
			<meta property="og:site_name" content={info.site_name} />
			<meta property="og:description" content={expected} />
			<meta property="og:image" content={image} />

			{ info.twitter.id && (
				<meta name="twitter:creator" content={info.twitter} />
			)}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={info.description} />
			<meta name="twitter:image" content={image} />
			<meta name="twitter:card" content={info.twitter.card} />
			
			{/* <link rel="preconnect" href="https://fonts.gstatic.com"/> */}
			{/* <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap" rel="stylesheet"/> */}
		</Helmet>
	)
}

export default Seo
